.phones-additional {
  &__btns {
    a {
      text-decoration: none;
      text-underline: none;
      display: block;
    }

    &-link-btn {
      width: 100%;
      padding: 15px;
      height: 48px;
      color: #4264fd;
      text-align: center;
      border-radius: 7px;
      border: 1px solid #4264fd;
      background-color: #fff;
    }
  }

  &__contacts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 10px;

    &-link {
      padding: 10px 15px;
      border-radius: 20px;
      background-color: #f8f8f8;
      color: #4264fd;
      font-family: $helvetica;
      font-size: 15px;
      text-decoration-line: underline;
    }
  }
}
